import Swiper from 'swiper';
import { gsap, TimelineLite, Power4 } from 'gsap';

let _root;
let tl;
let _overlayLayer;
let _closeOverlayBtn;
let _overlayMenu;
let _swiperContainer;
let _swiper;
let _currentInc = 0;
let _currentMenuDepthEl;
let _menuIsVisible = false;

export default class NavigationMobile {
    constructor(root) {
        _root = this;

        _overlayLayer = document.querySelector('#overlay-layer');
        _closeOverlayBtn = document.querySelector('#close-overlay');
        _overlayMenu = document.querySelector('#overlay-menu-container');
        _swiperContainer = _overlayMenu.querySelector('.scroll-swiper-ajax-content');

        this.initButton();

        window.addEventListener('resize', () => this.onResize());

    }

    onResize() {
        if (window.innerWidth > 992 && _menuIsVisible) {
            gsap.set(_overlayLayer, { display: 'none', width: 0 });
            gsap.set(_overlayMenu, { display: 'none', autoAlpha: 1 });
            gsap.set(_closeOverlayBtn, { display: 'none', autoAlpha: 1 });
            _root.reverseFunction();
        }
    }

    initButton() {
        const element = document.querySelector('#menu-toggle');

        if ('undefined' != typeof element && null != element) {
            _root.addEvent(element, 'click', _root.onClickMenuEvent);
        }
    }

    onClickMenuEvent(el) {
        el.preventDefault();

        _swiper = new Swiper(_swiperContainer, {
            direction: 'vertical',
            slidesPerView: 'auto',
            freeMode: true,
            init: false,
            scrollbar: {
                el: _swiperContainer.querySelector('.swiper-scrollbar')
            },
            mousewheel: true
        });

        if ('undefined' != typeof _swiperContainer && null != _swiperContainer) {
            _swiper.init();
        }

        tl = new TimelineLite({ onComplete: _root.onCompleteTimelineLite, onReverseComplete: _root.reverseFunction });

        _root.unloadScrollBars();

        tl.set(_overlayLayer, { display: 'block', width: 0 });
        tl.set(_overlayMenu, { display: 'block', autoAlpha: 0 });
        tl.to(_overlayLayer, 0.5, { width: '100%', ease: Power4.easeInOut });
        tl.to(_overlayMenu, 0.5, { autoAlpha: 1 });
        tl.set(_closeOverlayBtn, { display: 'block', autoAlpha: 0 });
        tl.to(_closeOverlayBtn, 0.5, { autoAlpha: 1 });

        document.querySelectorAll('#mobile-menu .caret').forEach(_caret => {
            _root.addEvent(_caret, 'click', _root.onCaretClickEvent);
        });

        _root.setBackButton();

        _root.removeEvent(document.querySelector('#menu-toggle'), 'click', _root.onClickMenuEvent);

        _menuIsVisible = true;
    }

    setBackButton() {
        const _back = document.querySelector('.mobile-menu-back');

        if ('undefined' != typeof _back && null != _back) {
            if (_currentInc === 0) {
                _root.removeEvent(_back, 'click', _root.onBackClickEvent);
                _back.style.display = "none";
            } else {
                _root.addEvent(_back, 'click', _root.onBackClickEvent);
                _back.style.display = "block";
            }
        }
    }

    setCurrentSubmenu(_trg) {
        _trg.parentElement.parentElement.querySelectorAll('.sub-menu').forEach(_sub => {
            _sub.style.display = "none";
        });
        _trg.parentElement.parentElement.querySelector('.sub-menu').style.display = "block";
    }

    onBackClickEvent(el) {
        _currentInc = _currentInc - 1;
        const menuHolder = document.querySelector('#nav-mob-holder');
        gsap.to(menuHolder, {
            duration: 0.5,
            x: -document.querySelector('#nav-mob-holder').getBoundingClientRect().width * (_currentInc),
            ease: Power4.easeInOut,
            onComplete() {
                _currentMenuDepthEl.parentElement.parentElement.querySelectorAll('#mobile-menu .sub-menu').forEach(_sub => {
                    _sub.style.display = "none";
                });
            }
        });
        _root.setBackButton();
        el.preventDefault();
    }

    onCaretClickEvent(el) {
        el.preventDefault();
        const _parent = el.currentTarget.parentElement.parentElement.parentElement.querySelector('.sub-menu');
        const _inc = parseInt(_parent.getAttribute('data-depth')) + 1;
        _root.menuAnimation(-(document.querySelector('#nav-mob-holder').getBoundingClientRect().width * _inc));
        _currentInc = _inc;
        _root.setBackButton();
        _root.setCurrentSubmenu(el.currentTarget);
        _currentMenuDepthEl = el.currentTarget;
    }

    menuAnimation(posX) {
        const menuHolder = document.querySelector('#nav-mob-holder');
        gsap.to(menuHolder, {
            duration: 0.5,
            x: posX,
            ease: Power4.easeInOut
        });
    }

    reloadScrollBars() {
        // document.documentElement.style.overflow = 'auto';
        // document.body.scroll = 'yes';
    }

    unloadScrollBars() {
        // document.documentElement.style.overflow = 'hidden';
        // document.body.scroll = 'no';
    }

    onCompleteTimelineLite() {
        _swiper.update();
        _root.addEvent(_closeOverlayBtn, 'click', _root.onClickCloseEvent);

        document.querySelectorAll('#mobile-menu .menu-item').forEach(_item => {
            _root.addEvent(_item.querySelector('a'), 'click', _root.onClickMenuItem);
        });
    }

    onClickMenuItem(el) {
        tl.reverse();
    }

    reverseFunction() {
        _root.reloadScrollBars();
        _swiper.destroy(true, true);
        _root.initButton();
        gsap.set(document.querySelector('#nav-mob-holder'), { x: 0 });
        _menuIsVisible = false;
    }

    onClickCloseEvent(el) {
        el.preventDefault();
        tl.reverse();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }
}