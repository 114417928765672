import Swiper from 'swiper/bundle';

let _root = this;

export default class SezioneCarosello {
    constructor(root) {
        _root = this;
        this.initSezioneCarosello();
    }

    refresh() {
        this.initSezioneCarosello();
    }

    initSezioneCarosello() {

        const swiperOptions = {
            speed: 300,
            grabCursor: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            autoHeight: false,
            init: false,
            mousewheelControl: true,
            pagination: {
                el: '.section-reviews-pagination',
                clickable: true,
            },
            breakpoints: {
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 2,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 40,
                },
                1400: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 50,
                },
            }
        };

        document.querySelectorAll('.section-reviews-swiper').forEach(_swiperContainer => {
            var swiper = new Swiper(_swiperContainer, swiperOptions);

            if ('undefined' != typeof _swiperContainer && null != _swiperContainer) {
                swiper.init();
            }
        });

    }
}