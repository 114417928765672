import { gsap, TimelineLite } from 'gsap';
import barba from '@barba/core';
import pageHightIndicator from './pagehightindicator';
import toTop from './totop';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
import initHeader from './header';
import initSezioneCarosello from './sezione-carosello';
import initSezioneAccordion from './sezione-accordion';
import startNavigationMobile from './navigation-mobile';
import parallax from './parallax';
import initScrollPageLink from './scroll-page-link';
import LazyLoad from "vanilla-lazyload";
import Menu from './menu';


gsap.registerPlugin(ScrollToPlugin);


let _root;
let _parallax;
let _allImages = 0;
let _countLoadedImages = 0;
let imagesLoaded = require('imagesLoaded');
let _tlProgress;
let _tweenInc;
let _caroselloLoghi;
let _preloaderProgress;
let _preloaderBar;
let _accordion;
let _lazyLoad;
let _scrollPageLink;
let _menu;

class App {
    static start() {
        return new App()
    }


    constructor() {
        _root = this;

        Promise
            .all([
                App.domReady(),
            ])
            .then(_root.init.bind(_root))
    }

    static domReady() {
        return new Promise(resolve => {
            document.addEventListener('DOMContentLoaded', resolve);
            let _imagesLoaded = imagesLoaded(document.querySelectorAll('.img-preloader'), { background: 'div' });

            _allImages = _imagesLoaded.images.length;
            _imagesLoaded.on('always', _root.onAlways);
            _imagesLoaded.on('progress', _root.onProgress);

        })
    }

    onAlways(instance) {
        _root.animationStart();
    }

    animationStart() {

        const _preloader = document.getElementById('preloader');
        
        _tlProgress = new TimelineLite({ delay: 1, onComplete: _root.onInitComplete });
        
        if ('undefined' != typeof _preloader && null != _preloader) {
            _tlProgress.set(_preloader, { display: 'block', autoAlpha: 1 });
            _tlProgress.to(_preloader, 0.5, { autoAlpha: 0 });

            _preloaderProgress = _preloader.querySelector('.preloader-progress');
            if ('undefined' != typeof _preloaderProgress && null != _preloaderProgress) {
                _tlProgress.set(_preloaderProgress, { display: 'block', autoAlpha: 1 });
                _tlProgress.to(_preloaderProgress, 0.5, { autoAlpha: 0 });
            }
            _preloaderBar = _preloader.querySelector('.preloader-bar');
        }
        
    }

    onInitComplete() {}

    progressUpdate() {
        let _width = Math.round(_tweenInc.progress() * 100);
        if ('undefined' != typeof _preloaderProgress && null != _preloaderProgress) {
            _preloaderProgress.textContent = _width + '%';
        }
    }

    onProgress(instance, image) {
        if (image.isLoaded) {
            _countLoadedImages++;
        }

        _tweenInc = gsap.to(_preloaderBar, {
            duration: 0.5,
            width: "100%",
            onUpdate: _root.progressUpdate,
            ease: gsap.Linear
        });
    }

    static showPage() {

        gsap.config({
            autoSleep: 60,
            force3D: false,
            nullTargetWarn: false,
            units: { left: '%', top: '%', rotation: 'rad' },
        });

        new initHeader();
        new pageHightIndicator();
        new toTop();
        new startNavigationMobile();

        _accordion = new initSezioneAccordion();
        _caroselloLoghi = new initSezioneCarosello();
        _scrollPageLink = new initScrollPageLink();
        _parallax = new parallax();

        _lazyLoad = new LazyLoad({
            elements_selector: '.lazy',
            use_native: true,
            callback_loaded: _root.callBackImgLoaded
        });

        _root.refreshPage();

        _menu = new Menu();

    }

    callBackImgLoaded(trg) {
        trg.parentElement.classList.contains('img-loaded') ? trg.parentElement.classList.remove('img-loaded') : trg.parentElement.classList.add('img-loaded');
    }

    init() {

        barba.hooks.afterLeave(data => {

            if (data.next.url.path) {

                document.getElementById('navigation').querySelectorAll('.menu-item').forEach(function(item) {

                    item.classList.remove('current-menu-item');

                    const item_href = item.querySelector('a').getAttribute('data-id');
                    let barba_url = data.next.url.path.replace(/\//g, '');

                    if ( !barba_url ) {
                        barba_url = 'home';
                    }

                    if (item_href === barba_url) {
                        item.classList.add('current-menu-item');
                    }
                });
            }
        });

        barba.init({

            async: true,
            prefetchIgnore: true,
            timeout: 50000,
            debug: false,
            preventRunning: false,

            transitions: [{
                name: 'one-pager-transition',
                leave(data) {
                    return gsap.to(data.current.container, 0.5, {
                        opacity: 0
                    });
                },
                after(data) {
                    return gsap.from(data.next.container, 0.5, {
                        opacity: 0,
                        delay: 0,
                        onComplete: _root.onCompleteAfter
                    });
                },
                afterEnter(data) {
                    gsap.set(window, { scrollTo: { y: 0 } });
                    _parallax.refresh();
                    _accordion.refresh();
                    _caroselloLoghi.refresh();
                    _scrollPageLink.refresh();
                    _lazyLoad.update();

                    
                }
            }]
        });

        barba.hooks.after((data) => {
            _root.refreshPage();
        });

        App.showPage();
    }

    refreshPage() {

        const logo = document.querySelector('#logo');
        

        if ( !document.querySelector('#content-body').classList.contains('home') ) {

            gsap.set(logo, { display: 'block', autoAlpha: 0 });
            gsap.to(logo, 0.5, { autoAlpha: 1 });


        } else {
            gsap.set(logo, { display: 'none', autoAlpha: 1 });
        }

    }

    onCompleteAfter() {
        _menu.refresh();
    }


}

App.start();