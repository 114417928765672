import { gsap, Power4 } from 'gsap';

let _root = this;

export default class pageHightIndicator {
  constructor(root) {
    _root = this;
    this.init();
  }

  init() {

    window.addEventListener('scroll', function (e) {
      _root.drawLength();
    });

    window.addEventListener('resize', function (e) {
      _root.drawLength();
    });

    _root.drawLength();

  }

  drawLength() {
    const container = document.querySelector('#page-height-indicator-bar');
    const bar = document.querySelector('#line-path');
    let barLength = container.offsetWidth;

    bar.getBoundingClientRect();

    let scrollPercentage =
      (document.documentElement.scrollTop + document.body.scrollTop) /
      (document.documentElement.scrollHeight - document.documentElement.clientHeight);

    let drawLength = barLength * scrollPercentage;

    gsap.to(bar, {
      duration: 0.3,
      width: drawLength
    });
  }

  addEvent(el, type, handler) {
    if (el.attachEvent) el.attachEvent('on' + type, handler);
    else el.addEventListener(type, handler);
  }

  removeEvent(el, type, handler) {
    if (el.detachEvent) el.detachEvent('on' + type, handler);
    else el.removeEventListener(type, handler);
  }

}
