import { gsap, Power4, TimelineLite } from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

let _root = this;

export default class SezioneAccordion {
  constructor(root) {
    _root = this;
    this.initSezioneAccordion();
  }

  refresh() {
    this.initSezioneAccordion();
  }

  initSezioneAccordion() {
    const element = document.querySelectorAll('.sezione-accordion-item');

    if ('undefined' != typeof element && null != element) {
      element.forEach(function (item) {
        _root.addEvent(item.querySelector('.title-collapse'), 'click', _root.onCkickEvent);
      });
    }
  }

  onCkickEvent(el) {
    var _this = el.target;
    var _next = _this.parentElement.querySelector('.body-collapse');

    if (!_this.classList.contains('active')) {
      _this.tl = new TimelineLite({ onComplete: _root.onCompleteTimelineLite, onCompleteParams: [_this] });

      _this.tl.set(_next, { display: 'block', height: 0 });
      _this.tl.to(_next, 0.5, { height: 'auto' });

      _this.classList.add('active');
    } else {
      _this.tl.reverse();
      _this.classList.remove('active');
    }
  }

  onCompleteTimelineLite(trg) {
    var scrollTop = trg.getBoundingClientRect().top + window.scrollY - 150;
    gsap.to(window, {
      duration: 0,
      scrollTo: { y: scrollTop },
      ease: Power4.easeInOut
    });
  }

  addEvent(el, type, handler) {
    if (el.attachEvent) el.attachEvent('on' + type, handler);
    else el.addEventListener(type, handler);
  }

  removeEvent(el, type, handler) {
    if (el.detachEvent) el.detachEvent('on' + type, handler);
    else el.removeEventListener(type, handler);
  }
}
