import { gsap, Power4, TimelineLite } from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

let _root = this;
let _scrollTriggerMenuAncora;
let _scrollTriggerMembers;

export default class ScrollPageLink {
    constructor() {
        _root = this;
        _root.setSize();
        //window.addEventListener('resize', () => _root.setSize);


        window.addEventListener("resize", function() { _root.setSize() }, true);

    }

    refresh() {
        setTimeout(function() { _root.setSize(); }, 300);

    }

    setSize() {
        this.init();
    }

    init() {
        const element = document.querySelectorAll('.scroll-page-link');
        const aboutMenuAncora = document.querySelector('.about-sottomenu-ancora');
        const pageType = document.querySelector('#about-sect-3-members');

        if ('undefined' != typeof element && null != element) {
            element.forEach(function(item) {
                _root.addEvent(item, 'click', _root.onCkickEvent);
            });
        }


        if (window.innerWidth >= 992) {

            if ('undefined' != typeof aboutMenuAncora && null != aboutMenuAncora) {
                _scrollTriggerMenuAncora = ScrollTrigger.create({
                    trigger: aboutMenuAncora,
                    start: "top 70",
                    endTrigger: "#footer",
                    pin: true,
                    pinSpacing: false,
                    scrub: 1
                });
            }

            if ('undefined' != typeof pageType && null != pageType) {
                _scrollTriggerMembers = ScrollTrigger.create({
                    trigger: "#about-sect-3-members",
                    start: "top 200px",
                    end: self => "+=" + (document.querySelector("#about-sect-3-members").offsetHeight - self.pin.offsetHeight),
                    pin: "#about-sect-3-txt",
                    onRefresh: self => self.pin.parentNode.style.float = "left",
                    pinSpacing: false
                });
            }

        } else {

            let triggers = ScrollTrigger.getAll();
            triggers.forEach(trigger => {
                trigger.kill();
            });

        }

    }

    onCkickEvent(el) {
        const _link = el.target.getAttribute('href');
        const _anchor = document.getElementById(_link);
        let scrollTop = 0;

        if ('undefined' != typeof _anchor && null != _anchor) {
            scrollTop = _anchor.getBoundingClientRect().top + window.scrollY - 150;

        }

        gsap.to(window, {
            duration: 1,
            scrollTo: { y: scrollTop },
            ease: Power4.easeInOut
        });
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }
}