import { gsap } from 'gsap';
import { map, lerp, clamp, getMousePos } from './utils';

let mousepos = { x: 0, y: 0 };

window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));

export default class MenuItem {
    constructor(el, inMenuPosition, animatableProperties) {
        this.DOM = { el: el };
        this.inMenuPosition = inMenuPosition;
        this.animatableProperties = animatableProperties;
        this.DOM.textInner = this.DOM.el.querySelector('.menu__item-textinner');
        this.layout();
        this.initEvents();
    }
    layout() {
        this.DOM.reveal = document.createElement('div');
        this.DOM.reveal.className = 'hover-reveal';
        this.DOM.reveal.style.perspective = '1000px';
        this.DOM.revealInner = document.createElement('div');
        this.DOM.revealInner.className = 'hover-reveal__inner';
        this.DOM.revealImage = document.createElement('div');
        this.DOM.revealImage.className = 'hover-reveal__img';
        this.DOM.revealImage.style.backgroundImage = `url(${this.DOM.el.getAttribute('data-img')})`;
        this.DOM.revealInner.appendChild(this.DOM.revealImage);
        this.DOM.reveal.appendChild(this.DOM.revealInner);
        this.DOM.el.appendChild(this.DOM.reveal);

        this.refresh = (el, inMenuPosition, animatableProperties) => {
            this.DOM = { el: el };
            this.inMenuPosition = inMenuPosition;
            this.animatableProperties = animatableProperties;
            this.DOM.textInner = this.DOM.el.querySelector('.menu__item-textinner');
            this.layout();
            this.initEvents();
        }
    }
    calcBounds() {
        this.bounds = {
            el: this.DOM.el.getBoundingClientRect(),
            reveal: this.DOM.reveal.getBoundingClientRect()
        };
    }
    initEvents() {

        this.mouseenterFn = (ev) => {
            this.showImage();
            this.firstRAFCycle = true;
            this.loopRender();
        };
        this.mouseleaveFn = () => {
            this.stopRendering();
            this.hideImage();
        };

        this.DOM.el.addEventListener('mouseenter', this.mouseenterFn);
        this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn);
    }
    showImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImage);

        this.tl = gsap.timeline({
                onStart: () => {
                    this.DOM.reveal.style.opacity = this.DOM.revealInner.style.opacity = 1;
                    gsap.set(this.DOM.el, { zIndex: 100 });
                }
            })
            .to(this.DOM.revealInner, 1.2, {
                ease: 'Expo.easeOut',
                startAt: { rotationY: -90, scale: 0.7 },
                rotationY: 0,
                scale: 1
            })
            .to(this.DOM.revealImage, 1.2, {
                ease: 'Expo.easeOut',
                startAt: { scale: 1.3, filter: 'blur(8px) brightness(2)' },
                scale: 1,
                filter: 'blur(0px) brightness(1)'
            }, 0);
    }
    hideImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImage);

        this.tl = gsap.timeline({
                onStart: () => {
                    gsap.set(this.DOM.el, { zIndex: 1 });
                },
                onComplete: () => {
                    gsap.set(this.DOM.reveal, { opacity: 0 });
                }
            })
            .to(this.DOM.revealInner, 0.5, {
                ease: 'Expo.easeOut',
                rotationY: 90,
                opacity: 0
            })
    }
    loopRender() {
        if (!this.requestId) {
            this.requestId = requestAnimationFrame(() => this.render());
        }
    }
    stopRendering() {
        if (this.requestId) {
            window.cancelAnimationFrame(this.requestId);
            this.requestId = undefined;
        }
    }
    render() {
        this.requestId = undefined;

        if (this.firstRAFCycle) {
            this.calcBounds();
        }

        this.animatableProperties.tx.current = Math.abs(mousepos.x - this.bounds.el.left) - this.bounds.reveal.width / 2;
        this.animatableProperties.ty.current = Math.abs(mousepos.y - this.bounds.el.top) - this.bounds.reveal.height / 2;

        this.animatableProperties.rotation.current = map(Math.abs(mousepos.x - this.bounds.el.left), 0, this.bounds.el.left + this.bounds.el.width, -20, 20);

        this.animatableProperties.tx.previous = this.firstRAFCycle ? this.animatableProperties.tx.current : lerp(this.animatableProperties.tx.previous, this.animatableProperties.tx.current, this.animatableProperties.tx.amt);
        this.animatableProperties.ty.previous = this.firstRAFCycle ? this.animatableProperties.ty.current : lerp(this.animatableProperties.ty.previous, this.animatableProperties.ty.current, this.animatableProperties.ty.amt);
        this.animatableProperties.rotation.previous = this.firstRAFCycle ? this.animatableProperties.rotation.current : lerp(this.animatableProperties.rotation.previous, this.animatableProperties.rotation.current, this.animatableProperties.rotation.amt);

        gsap.set(this.DOM.reveal, {
            x: this.animatableProperties.tx.previous,
            y: this.animatableProperties.ty.previous,
            rotation: this.animatableProperties.rotation.previous
        });

        this.firstRAFCycle = false;
        this.loopRender();
    }

}