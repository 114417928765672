import { gsap } from 'gsap';
import MenuItem from './menuItem';

export default class Menu {
    constructor() {
        this.init();
    }
    init() {
        const menuEl = document.querySelector('.menu');

        if ('undefined' != typeof menuEl && null != menuEl) {
            this.DOM = { el: menuEl };
            this.DOM.menuItems = this.DOM.el.querySelectorAll('.menu__item');
            this.animatableProperties = {
                tx: { previous: 0, current: 0, amt: 0.08 },
                ty: { previous: 0, current: 0, amt: 0.08 },
                rotation: { previous: 0, current: 0, amt: 0.06 }
            };
            this.menuItems = [];
            [...this.DOM.menuItems].forEach((item, pos) => this.menuItems.push(new MenuItem(item, pos, this.animatableProperties)));
            this.showMenuItems();
        }
    }
    showMenuItems() {
        gsap.to(this.menuItems.map(item => item.DOM.textInner), {
            duration: 1.2,
            ease: 'Expo.easeOut',
            startAt: { y: '100%' },
            y: 0,
            delay: pos => pos * 0.06
        });
    }
    refresh() {
        this.init();
    }
}