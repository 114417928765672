import { gsap, TimelineLite, Power4 } from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

let _root = this;
let _headerCurrentHeight;
let _header;
let _headerBg;
let _content;
let _stickyValue = 100;

export default class initHeader {
    constructor(root) {
        this.init();
    }

    init() {
        _root = this;

        _header = document.querySelector('#header');
        _content = document.querySelector('#content');

        if ('undefined' != typeof _header && null != _header) {
            const header_bg = document.createElement("div");
            header_bg.classList.add('header-bg');
            _header.append(header_bg);
            _headerBg = _header.querySelector('.header-bg');

            window.addEventListener('scroll', function(e) {
                _root.headerHeight();
            });

            _root.headerHeight();
            _root.initMenu();
        }

    }

    headerHeight() {

        _root.tweenHeightFunction(_header.querySelector('.header-bg'), _root.valueHeaderHeight(), 0.2, Power4.easeOut);

        const _toTop = document.querySelector('#scroll-to-top');
        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

        if ('undefined' != typeof _header && null != _header) {
            _stickyValue < scrollTop ? _header.classList.add('active') : _header.classList.remove('active');
        }

        if ('undefined' != typeof _content && null != _content) {
            _stickyValue < scrollTop ? _content.classList.add('active') : _content.classList.remove('active');
        }

        if ('undefined' != typeof _toTop && null != _toTop) {
            _stickyValue < scrollTop ? _toTop.classList.add('active') : _toTop.classList.remove('active');
            _root.addEvent(_toTop, 'click', _root.toTopFunction);
        }

        _headerCurrentHeight = _header.offsetHeight;

    }

    initMenu() {

        const _primaryMenu = document.querySelector('#main-navigation');
        if ('undefined' != typeof _primaryMenu && null != _primaryMenu) {
            let element = _primaryMenu.querySelectorAll('#main-navigation > .menu-item-has-children');

            if ('undefined' != typeof element && null != element) {
                element.forEach(function(item) {

                    let dropdownMenu = item.querySelector('.dropdown-menu');
                    dropdownMenu.tl = gsap.timeline({ paused: true });
                    dropdownMenu.tl.from(dropdownMenu.querySelectorAll('.menu-item'), {
                        duration: 0.3,
                        x: -30,
                        autoAlpha: 0,
                        ease: Power4.easeInOut,
                        delay: 0.1,
                        stagger: {
                            amount: 0.2
                        }

                    });

                    _root.addEvent(item, 'mouseenter', _root.menuItemMouseOver);
                    _root.addEvent(item, 'mouseleave', _root.menuItemMouseOut);

                });
            }

            _primaryMenu.querySelectorAll('.menu-item').forEach(function(item) {
                _root.addEvent(item.querySelector('a'), 'click', _root.onClickMenuItem);
            });

            // _root.addEvent(document.getElementById('contact-button'), 'click', _root.onClickMenuItem);

        }
    }

    onClickMenuItem(e) {

        const _primaryMenu = document.querySelector('#main-navigation');
        
        _primaryMenu.querySelectorAll('.menu-item').forEach(function(item) {
            item.classList.remove('current-menu-item');
        });
        e.target.parentElement.classList.add('current-menu-item');

        e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');
        const _header = document.querySelector('#header');
        // _headerCurrentHeight = _header.offsetHeight;
        _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');
        gsap.set(_headerBg, { height: 0 });

        // gsap.set(e.target.parentElement.parentElement.parentElement.parentElement.querySelector('.dropdown-menu').querySelectorAll('.menu-item'), { x: -30, autoAlpha: 0 });

    }

    menuItemMouseOver(e) {


        e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');


        const _header = document.querySelector('#header');
        _headerCurrentHeight = _header.offsetHeight;
        let dropdownMenu = e.target.querySelector('.dropdown-menu');
        let _hh = _headerCurrentHeight + dropdownMenu.offsetHeight;

        _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');

        _root.tweenHeightFunction(_headerBg, _hh, 0.3, Power4.easeOut);
        dropdownMenu.tl.play();
    }

    menuItemMouseOut(e) {

        e.target.classList.contains('activated') ? e.target.classList.remove('activated') : e.target.classList.add('activated');

        let dropdownMenu = e.target.querySelector('.dropdown-menu');
        dropdownMenu.tl.reverse();

        const _header = document.querySelector('#header');

        _header.classList.contains('open') ? _header.classList.remove('open') : _header.classList.add('open');

        _root.tweenHeightFunction(_headerBg, _root.valueHeaderHeight(), 0.3), Power4.easeIn;
    }

    valueHeaderHeight() {
        return _stickyValue < Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) ? '100%' : '0';
    }

    tweenHeightFunction(_trg, _hh, _speed, ease) {
        gsap.to(_trg, {
            duration: _speed,
            height: _hh,
            ease: ease
        });
    }

    toTopFunction() {
        gsap.to(window, {
            duration: 1,
            scrollTo: { y: 0 },
            ease: Power4.easeInOut
        });
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}