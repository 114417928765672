const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

const lerp = (a, b, n) => (1 - n) * a + n * b;

const clamp = (num, min, max) => num <= min ? min : num >= max ? max : num;

const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    //if (e.pageX || e.pageY) {
    posx = e.clientX;
    posy = e.clientY;
    // }
    // else if (e.clientX || e.clientY) {
    //     posx = e.clientX + body.scrollLeft + document.documentElement.scrollLeft;
    //     posy = e.clientY + body.scrollTop + document.documentElement.scrollTop;
    // }

    return { x: posx, y: posy }
};

const getRandomFloat = (min, max) => (Math.random() * (max - min) + min).toFixed(2);

export { map, lerp, clamp, getMousePos, getRandomFloat };